import api from '../api'
/**
 * 管理员用户相关api
 */
export class CustomerAddApi extends api {
  /**
   * 创建新的用户
   * @param {*} 对象内容
   */
  insert(form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/register`,
      method: 'post',
      data: form
    })
  }
  /**
   * 获取分页数据
   * @param {*} 分页请求数据
   */
  getRolePage({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/rolePage`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }

}

export default new CustomerAddApi('customerAdd')