import regionApi from '@/apis/common'

export function filterEmpty(data) {
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(a => {
      if (!Array.isArray(a.children) || a.children.length < 1) {
        a.children = null
      } else {
        filterEmpty(a.children)
      }
    })
  }
}
/**
 *
 * @param {级联选中项数组} val
 * @param {级联数据源} opt
 * @param {关联字段} key
 * @param {查询字段} name
 * @param {子级熟悉名} children
 */
export function getCascader(val, opt, key, name, children) {
  return val.map(function (value, index, array) {
    for (var itm of opt) {
      if (itm[key] === value) {
        opt = itm[children];
        return itm[name]
      }
    }
    return null
  })
}
/**
 * pcm下拉列查询
 */
export function getPCMSelect() {
  return [{
    id: '1',
    name: '一号项目'
  }]
}
/**
 * 位置信息下拉列查询
 */
export function getRegion(parentId) {
  regionApi.getRegionList(parentId).then(res => {
    return res.data
  })
}