<template>
  <el-form size="mini" ref="customerForm" :model="customer" :rules="formRules" label-width="120px" status-icon>
    <el-form-item label="账号" prop="account">
      <el-input v-model="customer.account" maxlength="20" show-word-limit placeholder="请输入员工账号" :disabled="id !== undefined && id.length > 0"></el-input>
    </el-form-item>
    <el-form-item label="姓名" prop="name">
      <el-input v-model="customer.name" placeholder="请输入员工姓名" maxlength="10" show-word-limit ></el-input>
    </el-form-item>
    <el-form-item label="手机号" prop="phone">
      <el-input v-model="customer.phone" placeholder="请输入手机号"></el-input>
    </el-form-item>
    <el-form-item label="邮箱" prop="email">
      <el-input v-model="customer.email" placeholder="请输入邮箱"></el-input>
    </el-form-item>
    <el-form-item label="部门" prop="deptId">
      <el-cascader
        v-model="customer.deptId"
        :options="allDepts"
        :props="props"
        ref="dept"
        filterable
        clearable
        placeholder="请选择父级部门"
        @change="handleChange"
        :show-all-levels="true"
        ></el-cascader>
    </el-form-item>
    <el-form-item label="启用" prop="state">
      <el-switch v-model="state" :active-value="1" :inactive-value="0"></el-switch>
    </el-form-item>
    <el-form-item label="角色">
      <el-button type="text" @click="handleSelectRoles">添加角色</el-button>
      <el-table ref="dataTable" stripe border fit size="mini" highlight-current-row :data="customer.roles" style="width: 100%">
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="desc" label="描述">
        </el-table-column>
        <el-table-column align="center" width="50">
          <template #default="scope">
            <i class="el-icon-delete" style="cursor:pointer;" alt="移除角色" @click="handleRemoveRole(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item>
      <el-button v-if="id" type="primary" @click="onSubmit(true)" v-waves>保存</el-button>
      <el-button v-if="!id" type="primary" @click="onSubmit(true)" v-waves>保存并继续</el-button>
      <el-button v-if="!id" type="primary" @click="onSubmit(false)" v-waves>保存并退出</el-button>
      <el-button v-waves @click="onCancel">取消</el-button>

      <filter-dialog ref="rolesTable" title="选择角色" v-model="selecteRoles.show" :rows="selecteRoles.rows" :total="selecteRoles.total" :index-column="true"
      :multiple-selected="true" width="80%" @page-info-change="handleRolePageInfoChange" :show-loading="selecteRoles.showLoading"
      @close="handleRolesDialogClose" @selected="handleRolesSelected">
        <template #condition>
          <el-form-item>
            <el-input @keyup.enter="handleRolesFilter" placeholder="角色名称" v-model="selecteRoles.condition.keyword" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleRolesFilter">搜索</el-button>
          </el-form-item>
        </template>
        <template #default>
          <el-table-column min-width="150px" label="名称" align="center" sortable="custom" property="name">
            <template #default="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150px" label="描述" align="left" header-align="center">
            <template #default="scope">
              <span>{{scope.row.desc}}</span>
            </template>
          </el-table-column>
        </template>
      </filter-dialog>
    </el-form-item>
  </el-form>
</template>

<style scoped>
  .el-form {
    margin: 20px;
  }

</style>

<script>
import customerAddApi from '@/apis/customer/customerAdd'
import customerInfoApi from '@/apis/customer/customerInfo'
import commonApi from '@/apis/common'
import waves from '@/directive/waves' // 水波纹指令
import { filterEmpty } from '@/utils/utils'

import filterDialog from '@/components/filterDialog/filterDialog'

export default {
  directives: {
    waves
  },
  props: ['id'],
  emits:['pageInfoChange', 'selected', 'close', 'page-info-change'],
  data () {
    return {
      customer: {
        id: null,
        account: '',
        name: '',
        password: '',
        state: 1,
        dept:null,
        deptId:null,
        roles: []
      },
      props: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly:true
      },
      allDepts:[],
      state: 1,
      formRules: {
        account: [{
            required: true,
            message: '请输入员工账号'
          }, {
            max: 20,
            message: '账号长度需少于20个字符',
            trigger: 'change'
          },{
          pattern: /^[0-9A-Za-z]{1,20}$/,
          message: '只能输入数字、字母'
          }],
          name: [{
            required: true,
            message: '请输入员工姓名'
          }, {
            max: 10,
            message: '员工姓名长度需少于10个字符',
            trigger: 'change'
          },{
          pattern: /^[a-z0-9A-Z\u4e00-\u9fa5]+$/,
          message: '只能输入中文、数字、字母'
        }],
        deptId:[{
            required: true,
            message: '请选择部门'
          }],
        phone:[{
            required: true,
            message: '请输入手机号'
          },
          {
            message: '手机号格式不正确',
            pattern: /^1[3456789]\d{9}$/,
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if(!!value){
                commonApi.verifyPhone(value, this.id).then(res => {
                  if (!res.data) {
                    callback(new Error('手机号：' + value + '已存在'))
                  } else {
                    callback()
                  }
                })
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        email:[
          {
          message: '邮箱格式不正确',
          pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
          trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if(!!value){
                //console.log('邮箱:',value)
                commonApi.verifyEmail(value, this.id).then(res => {
                  if (!res.data) {
                    callback(new Error('邮箱：' + value + '已存在'))
                  } else {
                    callback()
                  }
                })
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      },
      selecteRoles: {
        show: false,
        showLoading: false,
        rows: [],
        total: 0,
        condition: {
          keyword: undefined
        },
        pageInfo:{

        }
      }
    }
  },
  watch: {
    id () {
      this.resetForm()
    }
  },
  components: {
    filterDialog
  },
  methods: {
    /**
       * 提交数据
       * @param {*} goon 新增后是否继续新增
       */
    onSubmit (goon) {

      this.$refs.customerForm.validate((response) => {
        if (!response) {
          return
        }
        if (this.customer.roles && this.customer.roles.length > 0) {
          this.customer.roleIds = this.customer.roles.map(role => {
            return role.roleId
          })
        }
        this.customer.enabled = this.state === 1 ? 'YES' : 'NO'
        this.customer.state = this.state
        if (!this.id) {
          console.log('customer', this.customer);
          customerAddApi.insert(this.customer).then(response => {
            this.$message({
              title: '成功',
              message: '员工创建成功',
              type: 'success'
            })
            if (goon) {
              this.resetForm()
            } else {
              this.$router.replaceBy(this.$router.routesConfig.customerList)
            }
          })
        } else {
          customerInfoApi.update(this.customer).then(response => {
            this.$message({
              title: '成功',
              message: '员工更新成功',
              type: 'success'
            })
            this.$router.replaceBy(this.$router.routesConfig.customerList)
          })
        }
      })
    },
    
    /**
     * 级联菜单change事件处理
     */
    handleChange (value) {
      if(Array.isArray(value)){
        // let aa = this.$refs['dept'].getCheckedNodes(false)
        // if(aa[0].level == 3){
        //   this.leaderShow = false
        // }else{
        //   this.leaderShow = true
        // }
        this.customer.deptId = value[value.length - 1]
      }
      
    },
    /**
       * 取消按钮处理
       */
    onCancel () {
      this.$router.replaceBy(this.$router.routesConfig.customerList)
    },
    /**
       * 加载角色数据
       * @param {*} pageInfo 分页信息
       */
    getRoleList (pageInfo) {
      this.selecteRoles.showLoading = true
      if (this.id) {
        customerInfoApi.getRolePage({ pageInfo, condition: this.selecteRoles.condition }).then(response => {
          this.selecteRoles.rows = response.data.content
          this.selecteRoles.total = response.data.totalElements
          this.selecteRoles.showLoading = false
        }).catch(response => {
          this.selecteRoles.showLoading = false
        })
      } else {
        customerAddApi.getRolePage({ pageInfo, condition: this.selecteRoles.condition }).then(response => {
          this.selecteRoles.rows = response.data.content
          this.selecteRoles.total = response.data.totalElements
          this.selecteRoles.showLoading = false
        }).catch(response => {
          this.selecteRoles.showLoading = false
        })
      }
    },
    /**
       * 获取员工信息
    */
    getCustomer () {
      if (this.id) {
        customerInfoApi.getById(this.id).then(response => {
          this.customer = response.data
          this.customer.state = response.data.enabled === 'YES' ? 1 : 0
          this.state = this.customer.state
        })
      }
    },
    /**
       * 重置表单
       */
    resetForm () {
      this.$refs.customerForm.resetFields()
      this.customer.id = null
      this.customer.roles = []
      this.selecteRoles.rows = []
      this.selecteRoles.total = 0
      this.selecteRoles.condition.name = undefined
    },
    /**
       * 选择角色
       */
    handleSelectRoles () {
      this.selecteRoles.show = true
    },
    /**
       * 移除角色
       */
    handleRemoveRole (role) {
      this.customer.roles = this.customer.roles.filter(ar => {
        return !(ar.id === role.id)
      })
    },

    /**
       * 过滤角色
       */
    handleRolesFilter () {
      // this.getRoleList(this.selecteRoles.pageInfo)
      this.$refs.rolesTable.changeCondition()
    },
    /**
     * 角色选择
     */
    handleRolesSelected (roles) {
      roles.forEach(r => {
        if (!this.customer.roles.find(ar => {
          return ar.id === r.id
        })) {
          this.customer.roles.push(r)
        }
      })
    },
    /**
       * 选择角色窗口关闭
       */
    handleRolesDialogClose () {
      this.selecteRoles.condition.name = undefined
      this.selecteRoles.rows = []
      this.selecteRoles.show = false
    },
    /**
       * 查询角色条件变更
       */
    handleRolePageInfoChange (pageInfo) {
      this.selecteRoles.pageInfo = pageInfo
      this.getRoleList(pageInfo)
    },
    /**
     * 获取所有部门信息
     */
    getAllDepts () {
    if (this.id) {
      commonApi.getAllDepts().then(response => {
        this.allDepts = response.data
        // 过滤掉当前id
        filterEmpty(this.allDepts)
      })
    } else {
        commonApi.getAllDepts().then(response => {
        this.allDepts = response.data
        filterEmpty(this.allDepts)
      })
    }}
  },
  created () {
    this.getCustomer()
    this.getAllDepts()
  }
}

</script>
